a {
    color: black;
    text-decoration: none;
}

a.active {
    border-bottom: 1px solid black;
}

.nav a:hover {
    border-bottom: 1px solid black;
}

.nav a {
    margin: 0 0.5rem 0 0.5rem;
}

.nav {
    padding: 0.5rem;
}

.mobile-nav {
    display: none;
}

.mobile-nav a {
    margin: 0.5rem 0;
}

.mobile-nav.open {
    display: block;
    display: flex;
    flex-direction: column;
    margin: 1rem;
}

.mobile-nav a.active {
    border-bottom: 0;
    font-weight: 700;
}

.home-nav a:hover {
    background-color: rgb(0, 0, 0);
    color: white;
}

.shadow {
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.16);
}

button {
    background: #fff;
    border: 1.5px solid #000;
    color: #000;
    padding: 0.5rem 2rem;
    border-radius: 20px;
    cursor: pointer;
    font-weight: 500;
}

button:hover {
    opacity: 0.8;
}